import React from "react"

import "./layout.css"

const MovieReview = ({ movie }) => (
  <div class="bg-gray-800 rounded-lg shadow-xl p-6 m-3 flex items-center">
    <div class="flex-shrink-0 max-w-sm">
      <h1 class="text-xl text-gray-600">{movie.rating}</h1>
    </div>
    <div class="ml-6 pt-1">
      <h2 class="text-xl text-gray-100 leading-tight mb-2">{movie.name}</h2>
      <p class="text-base text-gray-400 leading-normal">{movie.review}</p>
    </div>
  </div>
)

export default MovieReview
