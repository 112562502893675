import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MovieReview from "../components/movie-review"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Kevsta's Movie Reviews" />
    {data.allMoviesJson.edges.reverse().map((entry, _) => {
      return <MovieReview movie={entry.node} />
    })}
  </Layout>
)

export const query = graphql`
  query {
    allMoviesJson {
      edges {
        node {
          name
          rating
          review
        }
      }
    }
  }
`

export default IndexPage
